<template>
  <BCard :header="$i18n.t('navigation.notifications')" header-bg-variant="primary" header-text-variant="white">
    <BTable
      sticky-header
      :items="notificationsOptionsList"
      bordered
      responsive
      :fields="fields"
      :busy="isBusy"
      hover
      class="products-table shadow-sm rounded"
      show-empty
      empty-text="No matching records found"
      small
    >
      <template #cell(notificationNameGeo)="data">
        <div class="d-flex flex-column">
          <span>{{ data.item.notificationNameGeo }}</span>
          <span>{{ data.item.notificationNameEng }}</span>
        </div>
      </template>
      <template #cell(editOptions)="data">
        <notification-edit-field :data="data.item" :updateFunction="handleupdateNotificationOption" />
      </template>
    </BTable>
  </BCard>
</template>

<script>
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import { BCard, BTable } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import NotificationEditField from "./NotificationEditField.vue";

export default {
  name: "Notifications",
  components: { BCard, BTable, NotificationEditField },
  computed: {
    ...mapState("notificationModule", ["notificationsOptionsList", "isBusy"]),
    ...mapState("accountSettings", ["roles"]),
    isNotificationsAllowed() {
      const hasAllowedRole = (allowedRoles = [], userRoles = []) => {
        return userRoles.some((role) => allowedRoles.includes(role));
      };

      return hasAllowedRole(["Admin", "Category Manager", "Editor"], this.roles);
    },
    fields() {
      return [
        {
          key: "notificationId",
          label: "ID",
        },
        {
          key: "notificationNameGeo",
          label: "Title",
        },
        {
          key: "editOptions",
          label: "Options",
        },
      ];
    },
  },
  created() {
    this.getNotifications();
  },
  methods: {
    ...mapActions("notificationModule", ["getNotificationOptions", "updateNotificationOption"]),
    toast(message = "Successfully updated", variant = "success") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "FileIcon",
          variant,
        },
      });
    },

    async getNotifications() {
      await this.getNotificationOptions();
    },
    async handleupdateNotificationOption(notificationId, values) {
      try {
        await this.updateNotificationOption({ id: notificationId, totalValues: values });
        await this.getNotifications();
        this.toast("Successfully updated", "success");
      } catch (err) {
        console.log(err);
        this.toast("Something went wrong", "danger");
      }
    },
  },
};
</script>

<style></style>
